.in-room-container {
    margin: auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.room-header {
    padding: 10px;
    display: flex;
}

.room-container {
    padding: 10px;
    display: flex;
}

@media screen and (max-width: 1080px) {
    .room-container {
        padding: 10px;
        display: block !important;
    }

    .list-users {
        margin-left: 0 !important;
        margin-top: 10px;
        width: 100%;
        max-width: 100% !important;
    }

    .room-header {
        padding: 10px;
        display: block !important;
    }

    .input-search-room {
        max-width: 100% !important;
        margin-left: 0 !important;
        margin-top: 10px;
    }

    .input-search-user {
        max-width: 100% !important;
        margin-left: 0 !important;
    }
}

.list-rooms {
    width: 100%;
    min-width: 200px;
}

.list-users {
    width: 100%;
    margin-left: 10px;
    min-width: 200px;
    max-width: 450px;
}

.input-search-room {
    max-width: 350px;
    margin-left: 10px;
}

.input-search-user {
    max-width: 350px;
}

.user-name {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    color: rgb(30, 127, 255);
    text-align: center;
}

.user-roles-td {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}