.game-menu {
  width: 100%;
}

.players {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
}

.player {
  width: 100%;
}

.joined {
  border: 2px solid #52c41a;
  color: #52c41a;
}

.current-user {
  border: 2px solid #1677ff;
  color: #1677ff;
}

.player-title {
  font-weight: 600;
  display: flex;
  padding: 5px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  gap: 10px;
}

.player-icon {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.name {
  font-weight: 500;
}

.time {
  margin-left: 10px;
  font-weight: 900;
  color: #1677ff;
}

.player-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.player-info>.info {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: 20px;
}

.info>.avatar {
  width: 40px;
  height: 40px;
}

.slot {
  cursor: pointer;
}

.slot:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.slot.full {
  cursor: not-allowed;
}

.avatar>img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.player-name {
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  max-width: 100px;
}

.number-of-wins {
  margin-bottom: 10px;
}

.competition-history-info {
  font-size: 13px;
  font-weight: 500;
}

.chat-area {
  min-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chat-title {
  text-align: center;
  font-weight: 600;
}

.chat-content {
  min-height: 300px;
  max-height: 450px;
  height: 100%;
  position: relative;
}

.chat-messages {
  border-radius: 8px;
  height: 370px;
  max-height: 400px;
  padding: 5px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.45) inset;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.users-in-room {
  height: 35px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 5px;
  padding-right: 5px;
  gap: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.group-message {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 370px !important;
  border-radius: 8px;
}

.chat-input {
  width: 100%;
  height: 32px;
}

.message {
  border-radius: 8px;
  padding: 5px;
  margin-bottom: 5px;
  background-color: #e4e4e4;
  color: black;
}

.my-message {
  border-radius: 8px;
  padding: 5px;
  margin-bottom: 5px;
  background-color: rgb(0, 132, 255);
  color: white;
}

@media only screen and (max-width: 1280px) {

  .game-introducing {
    margin-right: 20px;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 1140px) {
  .game-menu {
    max-width: 800px;
  }
}