*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-clip: padding-box;
  border: 4px solid transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;

}

*::-webkit-scrollbar-track {
  border-radius: 8px;
}

*::-webkit-scrollbar-corner {
  border-radius: 6px;
}

.container {
  margin: auto;
  max-width: 1280px;
  min-width: 350px;
}

#root {
  margin-top: 80px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1280px) {
  .container {
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 720px) {
  .notifications {
    display: none;
  }

  .author {
    width: 100%;
  }
}

.header-panel {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  min-height: 60px;
  background-color: rgba(255, 255, 255, 0.466);
  backdrop-filter: blur(30px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px;
  width: 100%;
}

.author {
  display: flex;
  gap: 5px;
  align-items: center;
}

.author>.link-to {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.link-to>a {
  text-decoration: none;
  color: #1677ff;
}

.notifications {
  width: 100%;
  position: relative;
}

.notification-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.list-errors {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.notifications>.error-count {
  position: absolute;
  left: 100%;
  top: 11px;
}

.user-profile {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  transition: all 0.3s ease-in-out;
  font-weight: bolder;
}

.user-profile:hover {
  --shadow-color: hsl(220deg 100% 55%);
  filter: drop-shadow(1px 2px 8px var(--shadow-color));
}

.match-info {
  padding: 5px;
  font-size: 14px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.4);
}

.message-bar {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: flex-end;
  margin-right: 20px;
  margin-left: 20px;
  z-index: 999;
  gap: 20px;
  height: 0px;
}

.message-card {
  min-width: 260px;
  width: 260px;
  min-height: 100px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transition: transform 0.3s ease-in-out;
  background-color: rgb(255, 255, 255);
}

.message-card>.title {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #1677ff;
  padding: 5px;
  color: white;
  display: flex;
}

.from-user {
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.close-message-action {
  float: right;
}

.have-message {
  animation: blinkingBackground 1.5s ease-in-out infinite;
}

@keyframes blinkingBackground {
  0% {
    background-color: #1677ff;
  }

  50% {
    background-color: #ff0000;
  }

  100% {
    background-color: #1677ff;
  }
}

.message-card .card-body {
  background-color: rgb(255, 255, 255);
  height: 300px;
  transition: height 0.3s ease-in-out;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.45) inset;
  min-height: 200px;
  border-radius: 6.5px;
  width: 250px;
  margin: 5px;
}

.message-card:has(.card-body.close) {
  transform: translateY(347px);
}

.message-card>.send-action {
  margin: 5px;
}

.messages {
  padding: 5px;
  border-radius: 6.5px;
  width: 240px !important;
  height: 290px !important;
}

.incomming-message {
  background-color: #d8d8d8;
  width: fit-content;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  align-self: flex-end;
  max-width: 150px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.outgoing-message {
  background-color: #53d1ec;
  color: white;
  width: fit-content;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  align-self: flex-start;
  max-width: 150px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.sent-date {
  font-size: 10px;
  font-weight: 700;
  transition: transform 0.3s ease-in-out;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transform: scale(0);
  width: fit-content;
  visibility: hidden;
  padding: 2px;
  opacity: 0;
  height: 0;
  width: 0;
}

.incomming-message:hover+.sent-date {
  align-self: flex-end;
}

.incomming-message:hover>.sent-date,
.outgoing-message:hover>.sent-date {
  transform: scale(1);
  transform-origin: 60% 0;
  visibility: visible;
  opacity: 1;
  height: 100%;
  width: 100%;
}

.list-conversations {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.ant-drawer-content-wrapper:has(>.conversations) {
  margin: 40px;
  box-shadow: none;
}

.ant-drawer-content-wrapper>.conversations>.ant-drawer-wrapper-body>.ant-drawer-body {
  padding: 5px;
}

#scrollableDiv {
  border: none !important;
  height: 100% !important;
  width: 300px;
}

.conversation-li {
  cursor: pointer;
  align-items: center !important;
  border-radius: 8px;
  margin-top: 5px;
  position: relative;
}

.conversation-item {
  align-items: center !important;
  position: relative;
}

.conversation-li:has(.unread) {
  background-color: #deecff;
}

.conversation-li:hover {
  cursor: pointer;
  background-color: #ccc !important;
}

.conversation-item.unread .ant-list-item-meta-title,
.conversation-item.unread .ant-list-item-meta-description {
  font-weight: bolder;
  color: black !important;
}

.conversation-item .ant-list-item-meta-title {
  font-weight: 500;
}

.conversation-li>.badge {
  position: absolute;
  right: 5px;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  display: none;
}

.conversation-li>.conversation-item.unread+.badge {
  display: block;
}

button.scroll-to-bottom::after {
  content: "\25BC";
  position: absolute;
  top: 3.5px;
  left: 3px;
  transform: scale(0.8, 0.65);
}

.admin-container {
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
  border-radius: 10px;
}

.admin-page {
  margin: auto;
  max-width: 1920px;
  min-width: 350px;
}

@media screen and (max-width: 1920px) {
  .admin-page {
      margin: auto 20px;
  }
}