.in-game-container {
    display: flex;
    margin: auto;
    margin-top: 20px;
    max-width: 1280px;
    flex-direction: row-reverse;
    gap: 10px;
}

@media only screen and (max-width: 1280px) {
    .in-game-container {
        display: flex;
    }
}

@media only screen and (max-width: 1140px) {
    .in-game-container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
    }
}