.home {
    display: block;
    max-width: 1280px;
    margin: auto;
}

.home-action {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.game-introducing {
    padding: 10px;
    max-width: 1280px;
    min-height: 50px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
}

.game-description {
    line-height: 1.38462;
    font-weight: 400;
    letter-spacing: -.005em;
}

.game-images {
    height: 100%;
    margin-left: 20px;
}

.game-images > img {
    max-height: 350px;
}

@media screen and (max-width: 720px) {
    .game-introducing {
        display: block !important;
    }
    .game-images {
        margin-top: 10px;
    }
    .game-images > img {
        width: 100%;
    }
}